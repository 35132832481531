<template>
  <div class="content">
    <div class="topbox">
      <div class="top clearfix">
        <div class="searchtop clearfix">
          <button class="btnicon">
            <md-icon name="search"
                     size="lg"></md-icon>
          </button>
          <input type="text"
                 placeholder="请输产品名称"
                 v-model="searchValue"
                 v-on:blur="yourmethod"
                 v-on:focus="getFocus"
                 class="searchinput">
          <button class="btnclear"
                  v-show="isclear"
                  @click="clearinput">
            <md-icon name="fail"
                     size="lg"
                     color="#666"></md-icon>
          </button>
        </div>
      </div>
      <div class="bottom">
        <div class="left">
          <input v-model="shortname"
                 readonly
                 @click="changeCompany"
                 type="text"
                 placeholder="保险公司"
                 class="chosegs"
                 style="color:#0066cc;width: 90%;" />
          <md-selector v-model="isSelectorShow"
                       :data="insuranceCompany"
                       :default-value="form.shortname"
                       max-height="320px"
                       title="请选择保险公司"
                       @choose="onSelectorChoose"></md-selector>
        </div>
        <!-- <Select  v-model="baseid"
            @change='selectCompany'>
          <option value="" disabled selected>保险公司</option>
          <Option
            v-for="(item, index) in insuranceCompany"
            :value="item.baseid"
            :key="index">{{ item.shortname }}</Option>
        </Select> -->

        <div class="right">
          <!-- <select  v-model="prodtype"
              @change='screenclick'>
            <option value="" disabled selected>公司产品分类</option>
            <option
              v-for="(item, index) in screenlist"
              :value="item.typecode"
              :key="index"
              >{{ item.typename }}</option
            >
          </select> -->
          <input v-model="typename"
                 readonly
                 @click="changeType"
                 type="text"
                 placeholder="产品分类"
                 class="chosegs"
                 style="text-align: right;color:#0066cc;width: 100%;" />
          <!-- <md-icon name="filter" size="md" class="icon"></md-icon> -->
          <md-selector v-model="isChoseShow"
                       :data="screenlist"
                       :default-value="form.typename"
                       max-height="320px"
                       title="请选择产品分类"
                       @choose="onTypeChoose"></md-selector>
        </div>
      </div>
    </div>
    <div class="contentbox">
      <md-scroll-view ref="scrollView"
                      v-show="!isNull"
                      auto-reflow
                      :scrolling-x="false"
                      @end-reached="loadMore">
        <div v-for="(item, index) in articlelist"
             :key="index"
             class="articleEntry">
          <div class="article"
               @click="jump(item)">
            <div class="left">
              <img :src="ossurl + item.iconhttp"
                   alt="">
              <p class="biaoqian"
                 v-if="item.typename">{{item.typename}}</p>
              <!-- <span class="toptips"
                    v-if="item.typename">{{item.typename}}</span> -->
            </div>
            <div class="right">
              <p class="title">{{item.prodname}}</p>
              <p class="article-content">{{item.pdesc}}</p>
            </div>
          </div>
        </div>
        <div class="showUrl"></div>
        <md-scroll-view-more slot="more"
                             :is-finished="loading">
        </md-scroll-view-more>
      </md-scroll-view>
      <div class="nodata"
           v-show="isNull">
        <img class="kongimg"
             src="@/assets/image/null.png"
             alt="" />
      </div>
    </div>
  </div>
</template>

<script>

import {
  choseSupplyList,
  showList,
  choseList
} from '@/api/agent/agent/index'
import { getStorage } from '@/lib/util'
import { thistle } from 'color-name'
import loadMore from '@/mixins/loadmore'// 下拉加载的
import { Toast } from 'mand-mobile'
export default {
  mixins: [loadMore], // 下拉加载的
  data () {
    return {
      typecode: '',
      ossurl: '',
      searchValue: '',
      page: 1,
      pageNo: 1,
      size: 10,
      baseid: '', // 保险公司选择
      prodtype: '', // 筛选的
      search: '', // 搜索的
      isSelectorShow: false,
      isChoseShow: false,
      classname: '',
      shortname: '',
      typename: '',
      // company:'',
      insuranceCompany: [],
      screen: '',
      screenlist: [],
      articlelist: [],
      form: {
        shortname: '',
        typename: ''
      },
      arr: [],
      arrtype: [],
      list: 10,

      isFinished: false,
      isNull: true,
      total: '',
      totalPage: '',
      height: '',
      loading: false,
      isclear: false
    }
  },
  components: {

  },
  beforeRouteLeave (to, from, next) {
    to.meta.title = this.classname
    next()
  },
  created () {
    this.typecode = this.$route.query.sclasscode || ''
    this.ossurl = getStorage('ossurl', '')
    // this.showList()
    this.choseSupplyList()
    this.choseList()
    if (this.typecode != '') {
      this.prodtype = this.typecode
      this.typename = this.$route.query.typename
      this.pageNo = 1
      this.getData()
    } else {
      this.getData() // 下拉加载
    }
    this.height = document.body.clientHeight - 106
  },

  mounted () {
    document.querySelector('.contentbox').style.height = `${this.height}px`
  },
  methods: {
    yourmethod () {
      this.pageNo = 1
      this.getData()
      // console.log("失去焦点",this.searchValue)
    },
    getFocus () {
      this.isclear = true
    },
    clearinput () {
      this.searchValue = ''
      this.isclear = false
      this.pageNo = 1
      this.getData()
    },
    jump (val) {
      let showhttp = val.showhttp
      this.classname = val.classname
      // console.log(val);
      this.$router.push(
        `/productShow?jumppath=${showhttp}&sclasscode=${val.sclasscode}`
      )
    },
    choseSupplyList () {
      choseSupplyList().then((res) => {
        this.arr = res.data.data
        this.insuranceCompany = []
        let arrd = {
          value: '',
          text: '全部'
        }
        this.insuranceCompany.push(arrd)
        for (var i = 0; i < this.arr.length; i++) {
          let arrobject = {
            value: this.arr[i].baseid,
            text: this.arr[i].shortname
          }
          this.insuranceCompany.push(arrobject)
        }
      })
    },
    // selectCompany(){
    //   this.showList()
    // },
    choseList () {
      choseList().then((res) => {
        this.arrtype = res.data.data
        this.screenlist = []
        let ddc = {
          value: '',
          text: '全部'
        }
        this.screenlist.push(ddc)
        for (var i = 0; i < this.arrtype.length; i++) {
          let arrtypeobj = {
            value: this.arrtype[i].typecode,
            text: this.arrtype[i].typename
          }
          this.screenlist.push(arrtypeobj)
        }
      })
    },
    // screenclick(){
    //   this.showList()
    //   console.log("筛选",this.prodtype);
    // },
    changeCompany () {
      this.isSelectorShow = true
    },
    onSelectorChoose ({ value, text }) {
      this.form.shortname = value
      this.shortname = text
      this.baseid = value
      // console.log("选择公司", this.shortname, this.baseid);
      this.pageNo = 1
      this.getData()
    },
    changeType () {
      this.isChoseShow = true
    },
    onTypeChoose ({ value, text }) {
      this.form.typename = value
      this.typename = text
      this.prodtype = value
      this.pageNo = 1
      this.getData()
    },

    async getData (isInit = true) {
      Toast.loading('加载中...')
      let { pageNo, size } = this
      if (!isInit) {
        this.pageNo = ++pageNo
        this.$refs.scrollView.finishLoadMore()
      }
      let data = {
        page: this.pageNo,
        size,
        baseid: this.baseid,
        prodtype: this.prodtype,
        search: this.searchValue
      }
      let res = await showList(data).finally(() => {
        Toast.hide()
      })
      this.articlelist = isInit ? res.data.data.rows : this.articlelist.concat(res.data.data.rows)
      if (this.articlelist.length == 0) {
        this.isNull = true
      } else {
        this.isNull = false
      }
      this.total = res.data.data.total
      this.totalPage = res.data.data.totalpage
      return res
    }
  }
}
</script>
<style scoped lang="stylus">
@import './index.styl';

.biaoqian {
  width: 70%;
  height: 0.5rem;
  background: #2598F3;
  position: absolute;
  color: white;
  font-size: 0.3rem;
  top: 0;
  right: 0;
  text-align: center;
  line-height: 0.5rem;
  overflow: hidden; /* 超出部分隐藏 */
  white-space: nowrap; /* 不换行 */
  text-overflow: ellipsis; /* 超出部分文字以...显示 */
  padding: 5px 12px;
  display: block;
}

.nodata {
  padding-top: 30%;
  text-align: center;
  padding-right: 15%;
  padding-left: 15%;
}
</style>
